import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';

function ForgetPasswordForm({ infos }) {
  const {
    register,
    errors,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    watch,
  } = useForm();
  const password = useRef({});
  password.current = watch('newPassword', '');
  const [success, setSuccess] = useState(false);

  const onSubmit = async (data, event) => {
    const np = data.newPassword;
    const cp = data.confirmPassword;
    const uid = infos.split('/')[1];
    const token = infos.split('/')[2];

    if (uid && token) {
      axios
        .post(
          'https://prod.api.gust.io/v1/public/users/password/password_reset_confirm/',
          {
            uid: uid,
            token: token,
            new_password: np,
            re_new_password: cp,
          }
        )
        .then(response => {
          if (response.data) {
            if (response.data.statusCode === 200) {
              setSuccess(true);
            }
          }
          reset();
          clearErrors();
        })
        .catch(error => {
          setSuccess(false);
          if (error.response.data.data) {
            if (error.response.data.data.new_password) {
              error.response.data.data.new_password.forEach(({ message }) =>
                setError('newPassword', {
                  type: 'manual',
                  message: message,
                })
              );
            } else if (error.response.data.data.token) {
              error.response.data.data.token.forEach(({ message }) =>
                setError('token', {
                  type: 'manual',
                  message: message,
                })
              );
            }
          }
        });
    }
  };

  return (
    <form
      className="justify-content-center"
      id="form"
      onSubmit={e => e.preventDefault()}
    >
      <div className="form-group form-group-align">
        <label htmlFor="inputNewPassword">New password:</label>
        <input
          type="password"
          name="newPassword"
          ref={register({
            required: 'You must specify a password',
            minLength: {
              value: 8,
              message: 'Password must have at least 8 characters',
            },
          })}
          className="form-control"
          id="inputNewPassword"
        />
        {errors.newPassword && (
          <p className="display-error">{errors.newPassword.message}</p>
        )}
      </div>
      <div className="form-group form-group-align">
        <label htmlFor="inputConfirmPassword">Confirm your password:</label>
        <input
          type="password"
          name="confirmPassword"
          className="form-control"
          id="inputConfirmPassword"
          ref={register({
            validate: value =>
              value === password.current || 'The passwords do not match',
          })}
        />
        {errors.confirmPassword && (
          <p className="display-error">{errors.confirmPassword.message}</p>
        )}
      </div>
      <button
        type="submit"
        onClick={handleSubmit(onSubmit)}
        className="btn btn-outline btn-xl btn-update-password"
      >
        Update Password
      </button>
      <div id="errors">
        {errors.token && (
          <p className="display-error">{errors.token.message}</p>
        )}
        {success && (
          <p className="display-success">
            Your password has been reset successfully
          </p>
        )}
      </div>
    </form>
  );
}

export default ForgetPasswordForm;
