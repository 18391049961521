import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import ForgetPasswordForm from '../components/ForgetPasswordForm';

const ForgetPasswordPage = props => {
  const infos = props.location.hash;

  return (
    <Layout>
      <div id="reset-password" className="min-vh-100">
        <div id="reset-password-content">
          <div className="container">
            <SEO title="Forget Password" />
            <div className="row text-white">
              <div className="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto text-center form p-4">
                <div className="text-center logo-container">
                  <img
                    src={'https://assets.gust.io/logo_white.webp'}
                    className="rounded"
                    alt="logo"
                  />
                </div>
                <h2 className="display-6 py-2 text-truncate">
                  Forget Password
                </h2>
                <div className="px-2">
                  <ForgetPasswordForm infos={infos} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgetPasswordPage;
